<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="row mb-3">
                    <label class="col-label form-label required tw-text-xs">
                        {{ $t('itemOvertime.oTName') }} 
                    </label>
                    <div>
                        <Input v-model="model.ot_name" :class="{
                            'is-invalid': errors.has('ot_name')
                        }" type="text" />
                        <div class="invalid-feedback" v-if="errors.has('ot_name')">
                            {{ errors.first('ot_name') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-label form-label required tw-text-xs">
                        {{ $t('itemOvertime.oTCategory') }}
                    </label>
                    <div>
                        <Select v-model="model.ot_category" :class="{
                            'is-invalid': errors.has('ot_category')
                        }">
                            <Option v-for="item in categories" :value="item.value" :key="item.value">
                                {{ item.label }}
                            </Option>

                        </Select>
                        <div class="invalid-feedback" v-if="errors.has('ot_category')">
                            {{ errors.first('ot_category') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-label form-label required tw-text-xs">
                        {{ $t('itemOvertime.rate(%)') }}
                    </label>
                    <div>
                        <Input v-model.number="model.ot_rate" :class="{
                            'is-invalid': errors.has('ot_rate')
                        }" type="number" />
                        <div class="invalid-feedback" v-if="errors.has('ot_rate')">
                            {{ errors.first('ot_rate') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-label form-label tw-text-xs">
                        {{ $t('itemOvertime.description') }}
                    </label>
                    <div>
                        <textarea v-model="model.description" class="form-control">
                        </textarea>
                    </div>
                </div>
                <div>
                    <checkbox v-model="model.is_taxable">
                        <label class="col-label form-label tw-text-xs">Taxable</label>
                    </checkbox>
                </div>
            </div>
        </div>
        <div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button v-if="!isUpdate" color="primary" outline :waiting="waiting_new"
                    :disabled="waiting_new || waiting" @click.prevent="onSaveAddNew">
                    {{ $t('saveAddNew') }}
                </ts-button>
                <ts-button v-if="!isUpdate" color="primary" :waiting="waiting" :disabled="waiting_new || waiting"
                    @click.prevent="onSave">
                    {{ $t('save') }}
                </ts-button>
                <ts-button v-if="isUpdate" color="primary" :waiting="waiting" :disabled="waiting_new || waiting"
                    @click.prevent="onUpdate">
                    {{ $t('update') }}
                </ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { debounce, isEmpty } from 'lodash'
export default {
    name: 'itemOvertimeForm',
    data() {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                ot_name: null,
                is_taxable: false,
                ot_category: null,
                ot_rate: null,
                description: null
            }
        }
    },
    computed: {
        ...mapState('payroll/itemOvertime', [
            'edit_data'
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data)
        },
        categories() {
            return [
                { value: 'Weekday', label: 'Weekday' },
                { value: 'Weekend', label: 'Weekend' },
                { value: 'Day Off', label: 'Day Off' },
                { value: 'Holiday', label: 'Holiday' }
            ]
        }
    },
    methods: {
        async onSave() {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/itemOvertime/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew() {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch(
                    'payroll/itemOvertime/store',
                    Object.assign({}, this.model)
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warnig',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate() {
            this.waiting = true
            this.$store
                .dispatch('payroll/itemOvertime/update', {
                    id: this.edit_data.ot_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData() {
            this.model.ot_name = this.edit_data.ot_name
            this.model.is_taxable = this.edit_data.is_taxable
            this.model.ot_category = this.edit_data.ot_category
            this.model.ot_rate = this.edit_data.ot_rate
            this.model.description = this.edit_data.description
        },
        clearInput() {
            this.errors = new Errors()
            this.model.ot_name = null
            this.model.is_taxable = false
            this.model.ot_category = null
            this.model.ot_rate = null
            this.model.description = null
        },
        notice(not) {
            this.$Notice[not.type]({
                title: 'ITEM OVERTIME',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    }
}
</script>

<style scoped>
</style>